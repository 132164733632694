import React, { useState } from "react";
import "./About.css";

function About() {
    return (
        <div className="about">
            <h1>About Me</h1>
            <p>
            </p>
        </div>
    );
}

export default About;